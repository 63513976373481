// Root reducer to combine all reducers in the app

import AuthReducer from "./AuthReducer";

import { combineReducers } from "redux";
import ErrorReducer, { SuccessReducer } from "./ErrorReducer";
import SettingsReducer, { NotificationReducer } from "./SettingsReducer";
import {
	BillerReducer,
	CategoryReducer,
	NetworkReducer,
	ProductReducer,
} from "./ProviderReducer";
import DataMainReducer from "./DataReducer";
import UseCaseReducer, { UpgradeReducer } from "./UseCaseReducer";
import TransactionsReducer from "./TransactionsReducer";
import WalletReducer, {
	BonusReducer,
	CommissionReducer,
	ReferralReducer,
} from "./WalletReducer";
import AirtimeReducer, {
	AirtimeConverterReducer,
	AirtimePinReducer,
} from "./AirtimeReducer";
import ElectricityReducer from "./ElectricityReducer";
import BizReducer, { ActivityReducer, BulkSMSReducer } from "./BizReducer";
import CableReducer from "./CableReducer";
import EducationReducer from "./EducationReducer";
import { FaqsReducer, ProviderStateReducer } from "./StatReducer";
import { docReducer } from "./SocketReducer";
import { CgWalletHistoryReducer } from "./CgWalletReducer";

export default combineReducers({
	auth: AuthReducer,
	errors: ErrorReducer,
	settings: SettingsReducer,
	notifications: NotificationReducer,
	success: SuccessReducer,
	category: CategoryReducer,
	biller: BillerReducer,
	products: ProductReducer,
	data: DataMainReducer,
	usecase: UseCaseReducer,
	upgrade: UpgradeReducer,
	network: NetworkReducer,
	transactions: TransactionsReducer,
	wallet: WalletReducer,
	bonus: BonusReducer,
	commission: CommissionReducer,
	airtimes: AirtimeReducer,
	airtimes_pin: AirtimePinReducer,
	converter: AirtimeConverterReducer,
	electricity: ElectricityReducer,
	education: EducationReducer,
	biz: BizReducer,
	cables: CableReducer,
	activity: ActivityReducer,
	referral: ReferralReducer,
	stat: ProviderStateReducer,
	faqs: FaqsReducer,
	documentation: docReducer,
	cgwallet: CgWalletHistoryReducer,
	bulk_sms: BulkSMSReducer,
});

