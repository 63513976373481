import React, { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GlobalState } from "./Data/Context";
import { Loader } from "./Utils";
import { ErrorPage } from "./Component";


const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	try {
		return createElement(component());
	} catch (error) {
		return <ErrorPage />;
	}
};

const PageRender = () => {
	const { auth, errors, clearErrors } = useContext(GlobalState);
	const { page, id, step } = useParams(),
		navigate = useNavigate(),
		escapeProducts = [
			"airtime",
			"data",
			"tv-subscriptions",
			"airtime_pin",
			"education",
			"biz",
			"auto-buy",
			"electricity-bills",
			"cgwallet",
			"bulk_sms",
		],
		escapeWallet = ["bonus", "commissions", "referral"];

	useEffect(() => {
		if (!auth?.isAuth) {
			if (errors?.errorText) {
				if (!["login", "register", "privacy", "terms"]?.includes(page)) {
					navigate("/");
				}
				clearErrors();
			}
		}
		if (auth?.isAuth) {
			if (["login", "register", "privacy", "terms"]?.includes(page)) {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate, errors?.errorText]);

	if (auth.token && auth.loading) return <Loader />;

	let pageName = "";
	if (step) {
		if (id === "biz" && ["verify"].includes(step))
			pageName = `${page}/${id}/${step}`;
		else pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (page === "products" && escapeProducts.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "wallets" && escapeWallet.includes(id))
			pageName = `${page}/${id}`;
		else pageName = `${page}/[id]`;
	} else {
		pageName = `${page}`;
	}

	return GeneratePage(pageName, auth?.user ? "Pages" : "Screens");
};

export default PageRender;
