import React, { useContext, useState, useEffect } from "react";
import Dashboard from "../Component/Dashboard";
import { GlobalState } from "../Data/Context";
import icon1 from "../assets/Group (4).png";
import icon2 from "../assets/Group (3).png";
import icon3 from "../assets/Group (3).png";
import icon4 from "../assets/Group (5).png";
import { productArr } from "../Component/Products";
// import moment from "moment";
import { ModalComponents } from "../Component";
import { Fragment } from "react";

const MainDashboard = () => {
  const { wallet, numberWithCommas, nairaSignNeutral, notifications } =
    useContext(GlobalState);
  let usersArr = [
		{
			name: "wallet balance",
			number: `${nairaSignNeutral} ${
				wallet?.balance?.available
					? numberWithCommas(Number(wallet?.balance?.available).toFixed(2))
					: 0
			}`,
			color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
			link: "/wallets",
			type: "amount",
			icon: icon1,
		},
		{
			name: "total Notification",
			number: notifications?.paginate?.total
				? numberWithCommas(notifications?.paginate?.total)
				: 0,
			color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
			link: "/notifications",
			icon: icon2,
		},
		{
			name: "total products",
			number: productArr?.filter(it => it?.show)?.length,
			color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
			link: "/products",
			icon: icon3,
		},
		{
			name: "total expenses",
			number: `${nairaSignNeutral} ${
				wallet?.wallet_details?.purchase
					? numberWithCommas(
							Number(wallet?.wallet_details?.purchase).toFixed(2)
					  )
					: 0
			}`,
			color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
			link: "/transactions",
			icon: icon4,
		},
	];
  let [notifyList, setNotifyList] = useState([]),
		[isOpen, setIsOpen] = useState(false),
		[keepOpen, setKeepOpen] = useState(true);

	useEffect(() => {
		if (notifications?.informed?.length > 0) {
			let res = notifications?.informed?.filter(
				item => item?.priority && item?.status === "play"
			);
			if (res?.length > 0) setNotifyList(res);
		}
	}, [notifications?.informed]);

	useEffect(() => {
		if (notifyList?.length > 0 && !isOpen) {
			setTimeout(() => {
				setIsOpen(true);
			}, 2000);
		}
		if (notifyList?.length === 0 || !keepOpen) setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifyList?.length]);

	let markAsNotified = async () => {
		setIsOpen(false);
	};

  return (
		<>
			<Dashboard usersArr={usersArr} />

			<ModalComponents
				isOpen={isOpen}
				title="Be informed"
				size={"sm"}
				borderNone={"borderNone"}
				toggle={() => {
					setKeepOpen(false);
					markAsNotified();
				}}>
				<div className="downH2 d-flex flex-column">
					{notifyList?.map((item, i) => (
						<Fragment key={i}>
							{item?.image?._id && (
								<>
									<img
										onClick={
											item?.linkURL
												? () => {
														window.open(
															item?.linkURL,
															item?.linkURL?.includes(window.location.origin)
																? ""
																: "_blank"
														);
												  }
												: null
										}
										src={item?.image?.url}
										alt={item?.image?.name}
										className={`img-fluid rounded imgFluid img-contain ${
											item?.linkURL ? "myCursor" : ""
										}`}
										style={{
											height: "auto",
											width: "100%",
										}}
									/>
								</>
							)}
							<p
								onClick={
									item?.linkURL
										? () => {
												window.open(
													item?.linkURL,
													item?.linkURL?.includes(window.location.origin)
														? ""
														: "_blank"
												);
										  }
										: null
								}
								className={`fw-bold Lexend text-center w-100 my-0 py-3 ${
									item?.linkURL ? "myCursor" : ""
								}`}>
								<span className="fontInherit me-2">
									{notifyList?.length > 1 && <>{i + 1}.</>}
								</span>{" "}
								{item?.message}
							</p>
							{/* <small className="d-block ms-auto Lexend mb-3">
								{moment(item?.createdAt).diff(moment(), "years") < 0
									? moment(item?.createdAt).format("L hh:mm A")
									: moment(item?.createdAt).diff(moment(), "months") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).diff(moment(), "days") < 0
									? moment(item?.createdAt).format("DD/MM hh:mm A")
									: moment(item?.createdAt).format("hh:mm A")}
							</small> */}
						</Fragment>
					))}
					<button
						onClick={() => {
							setKeepOpen(false);
							markAsNotified();
						}}
						className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto">
						close
					</button>
				</div>
			</ModalComponents>
		</>
	);
};

export default MainDashboard;
