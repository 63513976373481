import HomeThreeNav from "../../Component/home-three/home-three-nav";

import homeThreeHeaderBg from "../../assets/home-three-header-bg.png";
// import HomeThreeServiceCard from "../../Component/home-three/service-card";
import AppLogo from "../../Component/app-logo";
import { useNavigate } from "react-router-dom";
import { details } from "../../Component/Footer";
import Google from "../../assets/google.svg";
import Ios from "../../assets/ios.svg";

const HomeThree = () => {
	let navigate = useNavigate();

	return (
		<div
			className="home-three"
			style={{
				overflowX: "hidden",
			}}>
			<header style={{ background: `url(${homeThreeHeaderBg})` }}>
				<HomeThreeNav />
				<div className="container mx-auto py-10">
					<div className="grid md:grid-cols-2 gap-8">
						<div className="text-light self-center">
							<p className="text-4xl md:text-6xl font-bold leading-snug">
								Welcome to <br /> {process.env.REACT_APP_NAME}
							</p>
							<p className="mt-8">
								A technology platform that offers you best quality and
								affordable telecom services right from the comfort of your home
								or office.{" "}
							</p>
							<div className="py-2">
								<div className="mt-4 flex gap-5">
									<a
										href={process.env.REACT_APP_GOOGLE_PLAY || "#"}
										target={process.env.REACT_APP_GOOGLE_PLAY ? "_blank" : ""}
										rel="noopener noreferrer">
										<img
											src={Google}
											alt=""
											className="rounded-full h-10 lg:h-full"
										/>
									</a>
									<a
										href={process.env.REACT_APP_IOS_PLAY || "#"}
										target={process.env.REACT_APP_IOS_PLAY ? "_blank" : ""}
										rel="noopener noreferrer">
										<img
											src={Ios}
											alt=""
											className="rounded-full h-10 justify-center lg:justify-center gap-5 lg:h-full"
										/>
									</a>
								</div>
								<button
									className="bg-white px-12 h-12 rounded-full text-[#0C111F] font-medium"
									onClick={() => navigate("/login")}>
									Get Started
								</button>
							</div>
						</div>
						<div data-aos="zoom-in-up">
							<img src={require("../../assets/home-three-hero.png")} alt="" />
						</div>
					</div>
					<div className="flex justify-center">
						<img
							src={require("../../assets/home-three-cta.png")}
							alt=""
							className="h-20"
						/>
					</div>
				</div>
			</header>
			<section id="services" className="py-10 bg-[#ECEBF9]">
				<div className="container mx-auto">
					<p
						data-aos="fade-up"
						data-aos-duration="3000"
						className="text-2xl font-light text-[#0C111F] mb-1">
						Services
					</p>
					<p
						data-aos="fade-up"
						data-aos-duration="3000"
						className="text-4xl font-bold text-[#0C111F]">
						Our featured Services
					</p>
					{/* <div className="grid md:grid-cols-3 mt-12 gap-8">
						<HomeThreeServiceCard icon="home-three-serv1.png" />
						<HomeThreeServiceCard icon="home-three-serv2.png" />
						<HomeThreeServiceCard icon="home-three-serv1.png" />
					</div> */}
					<div className="grid md:grid-cols-3 gap-x-8 gap-y-10 mt-10">
						<Card
							img="ico-1.png"
							title="Data Services"
							text="Enjoy internet browsing always with our attractive data rates. Start your data reselling business with us today and enjoy profitable discounts for all networks."
						/>
						<Card
							img="ico-2.png"
							title="Airtime Services"
							text={`Making an online recharge has become very easy, fast and safe on ${process.env.REACT_APP_NAME}... We offer instant recharge of Airtime with discounts for all networks.`}
						/>
						<Card
							img="ico-3.png"
							title="Electricity"
							text="We offer fast recharge of prepared meter with instant token delivery. E.g PHEDC, EKEDC IKEDC, AEDC e.t.c."
						/>
						<Card
							img="ico-4.png"
							title="Education"
							text="Generate WAEC and NECO Result Checker PINs and Tokens at unbeatable prices"
						/>
						<Card
							img="ico-5.png"
							title="Tv Subscription"
							text="Instantly activate cable TV subscriptions such as DStv, GOtv and Startimes with favourable discounts."
						/>
						<Card
							img="ico-6.png"
							title="Recharge Card Printing"
							text="Print recharge cards of all networks in your desired business name"
						/>
						<Card
							img="ico-6.png"
							title="Airtime 2 Cash"
							text="Convert excess MTN, GLO, AIRTEL and 9MOBILE Airtime to Cash at Superb rates."
						/>
					</div>
				</div>
			</section>
			<section id="about-us">
				<div className="container mx-auto py-10">
					<div className="grid md:grid-cols-2 gap-8">
						<div className="self-center">
							<p
								data-aos="fade-up"
								data-aos-duration="3000"
								className="text-2xl font-light text-[#0C111F] mb-1">
								Benefits
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="3000"
								className="text-4xl font-bold text-[#0C111F]">
								Our Featured <br /> Services
							</p>
							<p data-aos="fade-up" data-aos-duration="3000" className="mt-8">
								Join our network of outstanding entrepreneurs partnering with{" "}
								{process.env.REACT_APP_NAME}. Bring our 'easy-payments'
								experience closer to your home or office and earn a commission
								for every transaction you perform for yourself or customers.
							</p>
							<div
								data-aos="fade-up"
								data-aos-duration="3000"
								className="flex gap-8 mt-4">
								<div className="border border-[#102F81] w-24 h-24 flex items-center justify-center flex-col">
									<p className="font-black text-xl mb-1">500+</p>
									<p>Customers</p>
								</div>
								<div className="border border-[#102F81] bg-[#102F81] w-24 h-24 flex items-center justify-center flex-col text-white">
									<p className="font-black text-xl mb-1">500+</p>
									<p>Customers</p>
								</div>
								<div className="border border-[#102F81] w-24 h-24 flex items-center justify-center flex-col">
									<p className="font-black text-xl mb-1">500+</p>
									<p>Customers</p>
								</div>
							</div>
						</div>
						<div data-aos="zoom-in-up">
							<img
								src={require("../../assets/hime-three-benefit.png")}
								alt=""
								className="h-[300px] md:h-[500px] mx-auto"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-gradient-to-r from-[#050436] to-[#0D03C3] py-10">
				<div className="container">
					<div className="grid md:grid-cols-2 gap-8">
						<div
							data-aos="fade-right"
							data-aos-duration="2000"
							className="text-light self-center">
							<p
								data-aos="fade-up"
								data-aos-duration="3000"
								className="text-2xl font-bold mb-8">
								How to get started
							</p>
							<div
								data-aos="fade-up"
								data-aos-duration="3000"
								className="flex gap-4 items-center">
								<div className="font-black text-2xl text-[#0D03C3] bg-white h-16 w-16 rounded-full flex items-center justify-center">
									1
								</div>
								<div>
									<p className="font-bold text-lg mb-1">Create Account</p>
									{/* <p className="font-lignt text-xs mb-0">
										Lorem ipsum dolor sit amet consectetur. <br /> Sodales nibh
										praesent.
									</p> */}
								</div>
							</div>
							<div
								data-aos="fade-up"
								data-aos-duration="3000"
								className="flex gap-4 items-center mt-8">
								<div className="font-black text-2xl text-[#0D03C3] bg-white h-16 w-16 rounded-full flex items-center justify-center">
									2
								</div>
								<div>
									<p className="font-bold text-lg mb-1">Install App</p>
									{/* <p className="font-lignt text-xs mb-0">
										Lorem ipsum dolor sit amet consectetur. <br /> Sodales nibh
										praesent.
									</p> */}
								</div>
							</div>
						</div>
						<div data-aos="zoom-in-up">
							<img
								src={require("../../assets/get-started.png")}
								alt=""
								className="md:h-[300px]"
							/>
						</div>
					</div>
				</div>
			</section>
			{/* <section className="py-10 bg-[#ECEBF9] text-center">
				<div className="container">
					<p
						data-aos="fade-up"
						data-aos-duration="3000"
						className="text-4xl font-bold text-[#0C111F] m-0">
						Subscribe to Our Newsletter
					</p>
					<p data-aos="fade-up" data-aos-duration="3000" className="mt-8">
						small heading
					</p>
					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className="flex bg-white p-1 rounded-md max-w-sm mx-auto">
						<input
							type="text"
							className="bg-transparent h-12 px-4 w-full"
						/>
						<button className="h-12 w-40 rounded-md bg-[#0C03B6] text-light">
							Join Now
						</button>
					</div>
				</div>
			</section> */}
			<section className="py-10">
				<div
					data-aos="fade-up"
					data-aos-duration="3000"
					className="container flex items-center gap-8">
					<div className="w-1/5">
						<img src={require("../../assets/home-three-customer.png")} alt="" />
					</div>
					<div>
						<div>
							<p className="text-4xl font-bold text-[#0C111F] m-0">
								Testimonials
							</p>
						</div>
						<div className="max-w-lg border-l-4 p-4 relative border-[#040D24] bg-light shadow mt-8">
							<p className="font-bold text-md capitalize m-0">Joy Doe</p>
							<p className="text-xs text-[#102F81]"></p>
							<p className="text-md text-[#102F81]">
								I enjoy excellent customer service and affordable telecom
								services with swift delivery daily while using this platform. If
								you ever think of making more profits in your data reselling
								business, think of {process.env.REACT_APP_NAME}
							</p>
							<div className="absolute h-32 w-32 -bottom-6 -right-6 bg-[#102F81] -z-[1]"></div>
						</div>
					</div>
				</div>
			</section>
			<footer
				style={{ background: `url(${homeThreeHeaderBg})` }}
				className="pt-32 pb-8 px-4">
				<div className="container">
					<div className="grid md:grid-cols-8 gap-12">
						<div className="col-span-4 text-white">
							<AppLogo height="50px" />
							<p className="max-w-lg mb-4 text-sm mt-4">
								{/* Own a VTU website like ours and carry out your business swiftly, */}
								You can start reselling by having access to all our services
								such as: DATA BUNDLES, AIRTIME VTU, BULK SMS, AIRTIME TO CASH,
								CABLE TV SUBSCRIPTIONS, ELECTRICITY BILLS PAYMENT, DATA CARDS,
								RECHARGE CARDS PRINTING etc at affordable prices.
							</p>
							{/* <div>socials</div> */}
						</div>
						<div className="d-none d-md-flex">
							<ul className="text-white">
								<li className="font-medium text-xl mb-6">About</li>
								<li className="text-xs font-normal mb-2">Services</li>
								<li className="text-xs font-normal mb-2">Contact Us</li>
							</ul>
						</div>
						<div className="d-none d-md-flex">
							<ul className="text-white">
								<li className="font-medium text-xl mb-6">Support</li>
								<li className="text-xs font-normal mb-2">FAQs</li>
								<li className="text-xs font-normal mb-2">Blog</li>
							</ul>
						</div>
						<div className="col-span-2">
							<ul className="text-white">
								<li className="font-medium text-xl mb-6">About</li>
								<li className="text-xs font-normal mb-2">Services</li>
								<li className="text-xs font-normal mb-2">Contact Us</li>
								<li className="text-xs font-normal mb-2">
									<ul className="list-group border-0 px-0 mx-0">
										{details.map((item, index) =>
											item?.text ? (
												<li
													key={index}
													className="list-group-item border-0 bg-transparent text-white d-flex align-items-center py-0">
													{item.icon}
													<p className="ms-3 w-100">
														{item?.type === "tel" ? (
															<>
																<a
																	className="text-white text-decoration-none fontReduce"
																	href={
																		!item?.text ? "#" : `tel:${item?.text}`
																	}>
																	{item?.text ? item?.text : ""}
																</a>
															</>
														) : item?.type === "mail" ? (
															<>
																<a
																	className="text-white text-decoration-none fontReduce"
																	href={
																		!item?.text ? "#" : `mailto:${item?.text}`
																	}>
																	{item?.text}
																</a>
															</>
														) : item?.type === "address" ? (
															<>
																<a
																	className="text-white text-decoration-none fontReduce w-100"
																	target={"_blank"}
																	rel="noreferrer"
																	href={
																		!item?.text
																			? "#"
																			: `https://www.google.com/maps/place/?q=place_id=${
																					details?.[details?.length - 1]?.text
																			  }`
																	}>
																	{item?.text}
																</a>
															</>
														) : (
															<></>
														)}
													</p>
												</li>
											) : (
												""
											)
										)}
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<p className="text-white text-center text-xs mt-8">
							Copyright @ {process.env.REACT_APP_NAME} 2023. All Rights
							Reserved.
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default HomeThree;

const Card = ({ img, title, text }) => {
	return (
		<div
			data-aos="zoom-in-up"
			data-aos-duration="2000"
			className="text-center bg-white rounded-lg p-8 shadow-md">
			<img
				src={require(`../../assets/${img}`)}
				className="mx-auto h-10"
				alt=""
			/>
			<p className="font-bold mt-8">{title}</p>
			<p className="font-light mt-4">{text}</p>
		</div>
	);
};
