import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
	Sidebar,
	DefaultHeader,
	SideHeader,
	ModalComponents,
} from "./Component";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import Home from "./Screens/home";
import Home2 from "./Pages/home";
import gif from "./assets/59945-success-confetti.gif";
import gif2 from "./assets/icons8-cancel.gif";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import HomeEight from "./Screens/home-eight";
import HomeFive from "./Screens/home-five";
import HomeFour from "./Screens/home-four";
import HomeThree from "./Screens/home-three";
import HomeNine from "./Screens/home-nine";
import HomeEleven from "./Screens/home-eleven";
import HomeFourteen from "./Screens/home-fourteen";
import HomeFifteen from "./Screens/home-fifteen";
import HomeTwo from "./Screens/home-two";
import HomeOne from "./Screens/home-one";
import HomeSixteen from "./Screens/home-sixteen";
import HomeNinteen from "./Screens/home-ninteen";

const Routers = () => {
	const { auth, success, restoreMsg, errors, clearErrors, logoutUser } =
			useContext(GlobalState),
		navigate = useNavigate();

	let handleLogOut = async e => {
			e?.preventDefault();
			logoutUser();
			navigate("/");
		},
		handleCloseError = () => {
			clearErrors();
			if (
				errors?.error?.error?.[0]?.msg
					?.toLowerCase()
					?.includes("insufficient wallet fund")
			) {
				navigate("/wallets");
			}
			if (
				errors?.error?.error?.[0]?.msg
					?.toLowerCase()
					?.includes("session timeout, please login again")
			) {
				handleLogOut();
			}
			if (
				errors?.error?.error?.[0]?.msg
					?.toLowerCase()
					?.includes("multiple logins detected. re-login to continue.")
			) {
				handleLogOut();
			}
		};

	return (
		<>
			<ToastContainer autoClose={false} />
			{auth?.user ? (
				<>
					<Sidebar />
					<SideHeader noLogo />
				</>
			) : (
				<></>
			)}
			<div className={auth?.user ? "home" : ""}>
				{auth?.user ? <DefaultHeader /> : <></>}
				<Routes>
					<Route
						path="/"
						element={
							auth?.user ? (
								<Home2 />
							) : ["Home Nineteen"]?.includes(process.env.REACT_APP_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Nineteen" ? (
								<HomeNinteen />
							) : ["Vickybest Telecom", "Home Sixteen"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Sixteen" ? (
								<HomeSixteen />
							) : [
									"Home eleven",
									"Moztelecom",
									"Moavox Pay",
									"Allbrite Digitals",
									"Noney Data Plug",
							  ]?.includes(process.env.REACT_APP_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Eleven" ? (
								<HomeEleven />
							) : ["Home fourteen", "V12 Pay"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Fourteen" ? (
								<HomeFourteen />
							) : ["Home fifteen", "Fantastic Fisayo", "MySub"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Fifteen" ? (
								<HomeFifteen />
							) : ["Home eight", "SPRINT DATA", "M.O Data Plug"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Eight" ? (
								<HomeEight />
							) : ["Home Five", "OG DATA", "OG NETWORK"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Five" ? (
								<HomeFive />
							) : ["Mel Data Services", "Yema Telecommunications"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Two" ? (
								<HomeTwo />
							) : [
									"Home Nine",
									"Vee's Telecom",
									"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
									"Durable Telecommunications",
							  ]?.includes(process.env.REACT_APP_NAME) ||
							  process.env.REACT_APP_TEMPLATE === "Template Nine" ? (
								<HomeNine />
							) : ["Home Four", "QuickTopUp Telecom"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Four" ? (
								<HomeFour />
							) : ["Home One", "Giwa Digital"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template One" ? (
								<HomeOne />
							) : ["Houseaid", "Rosezee Telecom", "Home Three"]?.includes(
									process.env.REACT_APP_NAME
							  ) || process.env.REACT_APP_TEMPLATE === "Template Three" ? (
								<HomeThree />
							) : (
								<Home />
							)
						}
					/>
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</div>
			<ModalComponents
				isOpen={success?.msg ? true : false}
				title="Success"
				size={"sm"}
				success="text-success text-succcess2"
				borderNone={"borderNone"}
				toggle={() => restoreMsg()}>
				<div className="downH2 d-flex flex-column">
					<div className="mx-auto">
						<img src={gif} alt="Gif" className="img-fluid" />
					</div>
					<p className="fw-bold Lexend text-center w-100">{success?.msg}</p>
					<button
						onClick={() => restoreMsg()}
						className="btn btn-success2 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5">
						close
					</button>
				</div>
			</ModalComponents>
			<ModalComponents
				isOpen={errors?.error?.error?.length > 0}
				title="Error"
				size={"sm"}
				success="text-danger text-danger2"
				borderNone={"borderNone"}
				toggle={handleCloseError}>
				<div className="downH2 d-flex flex-column">
					<div className="mx-auto mb-3">
						<img src={gif2} alt="Gif" className="img-fluid" />
					</div>
					{errors?.error?.error?.map((item, i) => (
						<p key={i} className="fw-bold Lexend text-center w-100">
							<span className="fontInherit me-2">{i + 1}.</span> {item?.msg}
						</p>
					))}
					<button
						onClick={handleCloseError}
						className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5">
						close
					</button>
				</div>
			</ModalComponents>
			{auth?.isAuth && (
				<div className="position-relative">
					<div
						className="position-absolute"
						style={{
							bottom: "2rem",
							right: "2rem",
						}}>
						{process.env.REACT_APP_AGENT_TWITTER && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#3199b7",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_TWITTER}
								target={"_blank"}
								title="Contact admin through Twitter"
								rel={"noreferrer"}>
								<BsTwitter size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_INSTAGRAM && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#ff476b",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_INSTAGRAM}
								target={"_blank"}
								title="Contact admin through Instagram"
								rel={"noreferrer"}>
								<BsInstagram size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_FACEBOOK && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#1a10c5",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_FACEBOOK}
								target={"_blank"}
								title="Contact admin through Facebook"
								rel={"noreferrer"}>
								<FaFacebookF size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_WHATSAPP_GROUP && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#20c997",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_WHATSAPP_GROUP}
								target={"_blank"}
								title="Join WhatsApp Group"
								rel={"noreferrer"}>
								<FaWhatsapp size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_WHATSAPP && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#20c997",
								}}
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={process.env.REACT_APP_AGENT_WHATSAPP}
								target={"_blank"}
								title="Contact admin through WhatsApp"
								rel={"noreferrer"}>
								<FaWhatsapp size={24} />
							</a>
						)}
						{process.env.REACT_APP_AGENT_EMAIL && (
							<a
								style={{
									height: "3rem",
									width: "3rem",
									background: "#d63384",
								}}
								title="Contact admin through email"
								className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
								href={`mailto:${process.env.REACT_APP_AGENT_EMAIL}`}
								target={"_blank"}
								rel={"noreferrer"}>
								<HiOutlineEnvelope size={24} />
							</a>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Routers;
