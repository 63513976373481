import React from "react";
import HomeFourteenNav from "../../Component/home-fourteen/HomeFourteenNav";
import { Link } from "react-router-dom";
import v12Group3 from "../../assets/v12Group3.png";
import { socials } from "../../Component/Footer";

const HomeFourteen = () => {
	const card = [
		{
			id: 1,
			img: v12Group3,
			title: "Airtime Services",
			text: `${process.env.REACT_APP_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
		},
		{
			id: 2,
			img: v12Group3,
			title: "Data Services",
			text: `${process.env.REACT_APP_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
		},
		{
			id: 3,
			img: v12Group3,
			title: "Airtime Pin Service",
			text: `${process.env.REACT_APP_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime. `,
		},
		{
			id: 4,
			img: v12Group3,
			title: "Bill Payment",
			text: "Do you want to stay on top of your finances, then make you can make use of our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools. ",
		},
		{
			id: 5,
			img: v12Group3,
			title: "Cable Subscription",
			text: `At ${process.env.REACT_APP_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
		},
		{
			id: 6,
			img: v12Group3,
			title: "Education",
			text: `${process.env.REACT_APP_NAME} is your go-to source for various examination scratch cards, including those for Waec, Neco, and Nabteb. We take pride in providing top-quality service and aim to make our website your go-to destination after your first experience with us. `,
		},
	];
	return (
		<div name="product" className=" h-screen">
			<HomeFourteenNav />

			{/* Hero */}
			<div
				data-aos="fade-right"
				data-aos-duration="3000"
				className=" px-8 md:px-16 lg:px-28 lg:flex md:grid grid mt-[82px] pt-[100px] items-center justify-between bg-gradient-to-b from-[#0C0532] to-[#313284]">
				<div className=" lg:block md:hidden hidden  ">
					<img
						className=""
						src={require("../../assets/v12Heroing.png")}
						alt=""
					/>
				</div>
				<div className="  ">
					<div data-aos="fade-right" data-aos-duration="3000" className="">
						<p className="lg:w-[552px] text-[64px] font-DM_Sans text-white font-bold">
							{process.env.REACT_APP_NAME}
						</p>
						<p className=" lg:w-[752px] lg:mt-[-25px] text-[64px] font-DM_Sans text-white font-bold ">
							Fast. Accurate. Reliable
						</p>
						<p className=" text-white pb-3 ">
							We prioritize customer satisfaction by offering an extensive range
							of choices, ensuring their happiness through diverse options and
							personalized solutions for a more fulfilling experience.
						</p>

						{/* Buttons */}
						<div className=" lg:flex md:flex flex items-center lg:gap-5 md:gap-5 gap-2 mt-20 pb-5 ">
							<Link
								to="/register"
								className=" lg:w-[175px] md:w-[175px] w-[165px] h-[49px] hover:scale-105 duration-300 ease-in justify-center items-center flex font-DM_Sans rounded-md no-underline bg-white text-black">
								Get Started
							</Link>

							<a
								className=" no-underline hover:scale-105 duration-300 ease-in lg:pl-3 md:pl-3 "
								href={process.env.REACT_APP_GOOGLE_PLAY || ""}>
								<button className=" lg:w-[175px] md:w-[175px] w-[165px] h-[49px] gap-2 px-4 flex items-center justify-center rounded-md no-underline bg-white text-black">
									<img
										className="lg:w-[36px] md:w-[36px] w-[20px] lg:h-[36px] md:h-[36px] h-[20px]"
										src={require("../../assets/v12Icon.png")}
										alt=""
									/>
									<p className=" mt-3">
										Available now{" "}
										<span className=" font-bold font-DM_Sans ">Play Store</span>
									</p>
								</button>
							</a>
						</div>
						<div className=" lg:hidden md:block  block lg:px-0 md:px-10 md:py-10 pl-4 ">
							<img
								className=""
								src={require("../../assets/v12Heroing.png")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Available */}

			<div className=" grid justify-center items-center py-16">
				<div className=" grid">
					<p
						data-aos="fade-right"
						data-aos-duration="3000"
						className=" lg:text-[36px] md:text-[30px] text-[20px] text-center font-bold font-kumbh bg-gradient-to-r from-[#42A1EF] to-[#1D4ED8] text-transparent bg-clip-text">
						AVAILABLE NETWORKS
					</p>
				</div>
				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className="px-8 md:px-16 lg:px-28 grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2 justify-between items-center gap-10 md:gap-8 lg:gap-20 mt-3">
					<img
						className="w-[125px] h-[60px]"
						src={require("../../assets/MTN.png")}
						alt=""
					/>
					<img
						className="w-[119px] h-[60px]"
						src={require("../../assets/GLO.png")}
						alt=""
					/>
					<img
						className="w-[142px] h-[60px]"
						src={require("../../assets/AIRTEL.png")}
						alt=""
					/>
					<img
						className="w-[160px] h-[60px]"
						src={require("../../assets/9MOBILE.png")}
						alt=""
					/>
				</div>
				<div className=" flex justify-between items-center">
					<img
						className=" w-[200px] h-[200px] absolute left-0"
						src={require("../../assets/Ellipseleft.png")}
						alt=""
					/>
					<img
						className=" w-[200px] h-[200px] absolute right-0"
						src={require("../../assets/Ellipseright.png")}
						alt=""
					/>
				</div>
			</div>

			{/* WHY CHOOSE US */}
			<div className="px-8 md:px-16 lg:px-28 text-white bg-gradient-to-r   from-[#17124A] to-[#1D4ED8] py-16 lg:flex md:grid grid justify-center items-center">
				<div data-aos="fade-up" data-aos-duration="3000" className="">
					<p>WHY CHOOSE US</p>
					<p className="lg:text-[35px] md:text-[34px] text-[20px] lg:leading-[37px] font-bold lg:w-[570px] font-kumbh">
						{" "}
						We have the BEST Features For Your Subscriptions. You can trust us
						with your subscriptions.
					</p>
					<p className=" lg:w-[400px] font-kumbh">
						In a few clicks, buy data to keep surfing the internet. You can buy
						whatever size of data plan for whichever network you desire.
					</p>
					{/* dash */}
					<div className=" lg:w-[428px] py-2">
						<img
							className=" lg:w-[428px]"
							src={require("../../assets/V12Line.png")}
							alt=""
						/>
					</div>
					<div className="grid">
						<div className=" flex items-center gap-3">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/v12check.png")}
								alt=""
							/>
							<p className="mt-3">Secure Transactions</p>
						</div>
						<div className=" flex items-center gap-3">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/v12check.png")}
								alt=""
							/>
							<p className="mt-3">Fast Delivery/Response</p>
						</div>
						<div className=" flex items-center gap-3">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/v12check.png")}
								alt=""
							/>
							<p className="mt-3">24/7 Customer Support</p>
						</div>
						<Link
							to="/login"
							className=" w-[168px] h-[40px] mt-3 bg-white no-underline flex justify-center items-center rounded-md text-black font-kumbh">
							Login
						</Link>
					</div>
				</div>
				<div
					data-aos="fade-down"
					data-aos-duration="3000"
					className=" grid justify-center items-center  ">
					<img
						className="relative bottom-[-12px] lg:mt-0 md:mt-0 mt-5"
						src={require("../../assets/v12Group.png")}
						alt=""
					/>
				</div>
			</div>

			{/* Benefits you gain with US */}
			<div className="px-8 md:px-16 lg:px-28 lg:flex md:grid grid pt-16 justify-between items-center ">
				<div data-aos="fade-right" data-aos-duration="3000">
					<p className=" lg:w-[484px] lg:text-[40px] md:text-[30px] text-[20px] font-kumbh font-bold bg-gradient-to-r from-[#42A1EF] to-[#1D4ED8] text-transparent bg-clip-text">
						Benefits you gain with US
					</p>
					<p className=" lg:w-[441px] font-kumbh">
						Empower Your Financial Journey with the Ultimate Solution for
						Convenience and experience the ease and security of managing your
						finances with our innovative and comprehensive solution.
					</p>
					<div>
						<div className="flex items-center gap-3">
							<img
								className=" w-[24px] h-[24px]"
								src={require("../../assets/Interestrate.png")}
								alt=""
							/>
							<p className="mt-3">Best interest rates on the market.</p>
						</div>
						<div className="flex items-center gap-3">
							<img
								className=" w-[24px] h-[24px]"
								src={require("../../assets/Increase.png")}
								alt=""
							/>
							<p className="mt-3">Prevent unstable prices</p>
						</div>
						<div className="flex items-center gap-3">
							<img
								className=" w-[24px] h-[24px]"
								src={require("../../assets/Tag.png")}
								alt=""
							/>
							<p className="mt-3">Best price on the market</p>
						</div>
						<div className="flex items-center gap-3">
							<img
								className=" w-[24px] h-[24px]"
								src={require("../../assets/Shield.png")}
								alt=""
							/>
							<p className="mt-3">Security of your data</p>
						</div>
					</div>
				</div>
				<div data-aos="fade-left" data-aos-duration="3000">
					<img src={require("../../assets/v12Group2.png")} alt="" />
				</div>
			</div>

			{/* Our Services Made Specially for YOU! */}
			<div
				name="about"
				className=" px-8 md:px-16 lg:px-28 bg-gradient-to-r  from-[#181E68] to-[#1D4ED8] text-white py-16">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid justify-center items-center">
					<p className=" text-center lg:text-[48px] md:text-[36px] text-[30px] font-kumbh font-bold">
						Our Services Made Specially for YOU!
					</p>
					<p className=" font-kumbh lg:w-[900px] text-center lg:text-[24px] md:text-[24px] text-[20px]  lg:mt-0 md:mt-0 mt-3">
						We have the BEST Features For Your Subscriptions. You can trust us
						with your subscriptions.
					</p>
				</div>

				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
					{card.map(items => (
						<div
							className=" flex lg:w-[331.14px] md:[391.14px] h-auto w-full gap-3"
							key={items.id}>
							<img
								className=" lg:w-[70px] md:w-[70px] w-[50px] lg:h-[70px] md:h-[70px] h-[50px]"
								src={items.img}
								alt=""
							/>
							<div className=" grid">
								<p className=" font-bold">{items.title}</p>
								<p>{items.text}</p>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* GETTING STARTED WITH V12 PAY */}
			<div className="px-8 md:px-16 lg:px-28 pt-16 pb-8">
				<div className="  grid justify-center items-center">
					<p
						data-aos="fade-down"
						data-aos-duration="3000"
						className=" text-center lg:text-[40px] md:text-[30px] text-[20px] font-kumbh font-bold bg-gradient-to-r from-[#42A1EF] to-[#1D4ED8] text-transparent bg-clip-text">
						GETTING STARTED WITH {process.env.REACT_APP_NAME}
					</p>
				</div>
				<div className=" lg:flex md:grid grid justify-center items-center">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className="lg:w-1/2 md:w-full w-full">
						<img src={require("../../assets/v12phoneimg.png")} alt="" />
					</div>
					<div className="grid lg:w-1/2 md:w-full w-full">
						<div
							data-aos="fade-left"
							data-aos-duration="3000"
							className=" lg:w-[450px]">
							<p className=" font-kumbh font-bold">How to Get Started:</p>

							<div className="">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[32px] h-[32px]"
										src={require("../../assets/v12Group4.png")}
										alt=""
									/>
									<p className=" font-bold  font-kumbh mt-3">
										Download and Install {process.env.REACT_APP_NAME} from
										Google PlayStore
									</p>
								</div>
								<div className=" flex items-center gap-10 mt-[-20px]">
									<img
										className=" w-[12.03px] h-[40.03px] lg:relative left-3"
										src={require("../../assets/v12Line8.png")}
										alt=""
									/>

									<p className=" lg:w-[288px] font-kumbh mt-3">
										{process.env.REACT_APP_NAME} is available on Google
										PlayStore, Go to PlayStore, download and install the app in
										fe minutes
									</p>
								</div>
							</div>

							{/* card2 */}
							<div className="">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[32px] h-[32px]"
										src={require("../../assets/v12Group5.png")}
										alt=""
									/>
									<p className=" font-bold  font-kumbh mt-3">
										Register on the Installed App for Free
									</p>
								</div>
								<div className=" flex items-center gap-10 mt-[-20px]">
									<img
										className=" w-[12.03px] h-[40.03px] lg:relative left-3"
										src={require("../../assets/v12Line8.png")}
										alt=""
									/>

									<p className=" lg:w-[288px] font-kumbh mt-3">
										Once you open the app, follow the sign-up process to have
										full access to the app. It takes few minutees to register.
									</p>
								</div>
							</div>

							{/* card3 */}
							<div className="">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[32px] h-[32px]"
										src={require("../../assets/v12Group6.png")}
										alt=""
									/>
									<p className=" font-bold  font-kumbh mt-3">
										Add Funds, Pay Bills Easily
									</p>
								</div>
								<div className=" flex items-center gap-10 mt-[-20px]">
									<div className="w-[12.03px] h-[40.03px] lg:relative left-3"></div>

									<p className=" lg:w-[288px] font-kumbh mt-3">
										Now you have limited access to the App. Enjoy it to the
										fullest.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Embracing A Brighter Financial Life With V12 Pay, Start Today! */}
			<div
				data-aos="fade-right"
				data-aos-duration="3000"
				className="px-8 md:px-16 lg:px-28 grid justify-center items-center">
				<p className=" font-kumbh lg:text-[40px] md:text-[30px] text-[20px] font-bold lg:w-[884px] text-center">
					Embracing A Brighter Financial Life With {process.env.REACT_APP_NAME},
					Start Today!
				</p>
				{/* Buttons */}
				<div className=" lg:flex md:flex grid items-center justify-center lg:gap-5 md:gap-5 gap-5  py-10 ">
					<Link
						to="/register"
						className=" w-[175px] h-[49px] justify-center items-center flex font-DM_Sans rounded-md no-underline text-white bg-[#1D4ED8]">
						Get Started
					</Link>

					<a className=" no-underline" href={process.env.REACT_APP_GOOGLE_PLAY}>
						<button className=" w-[175px] h-[49px] gap-2 px-4 flex items-center justify-center rounded-md no-underline text-white bg-[#1D4ED8]">
							<img
								className="w-[36px] h-[36px]"
								src={require("../../assets/v12whiteIcon.png")}
								alt=""
							/>
							<p className=" mt-3">
								Available now{" "}
								<span className=" font-bold font-DM_Sans">Play Store</span>
							</p>
						</button>
					</a>
				</div>
			</div>

			{/* footer */}
			<div
				name="contact"
				className="px-8 md:px-16 lg:px-44 pt-16 bg-[#192374] w-full lg:flex md:flex grid justify-between py-10 lg:gap-0 md:gap-4 gap-5  text-white">
				<div data-aos="fade-left" data-aos-duration="3000" className=" ">
					{/* <img
            className=" w-[100px] h-[100px]"
            src={require("../../assets/v12paylogo.png")}
            alt=""
          /> */}
					<p className=" font-kumbh w-[230px]">
						We prioritize customer satisfaction by offering an extensive range
						of choices, ensuring their happiness through diverse options and
						personalized solutions for a more fulfilling experience.
					</p>
					<ul className="list-group border-0 list-group-horizontal">
						{socials.map((item, index) => (
							<li
								key={index}
								className="list-group-item border-0 bg-transparent text-[#fff] d-flex align-items-center">
								<a
									className="text-[#fff] text-decoration-none fontReduce"
									target={"_blank"}
									rel="noreferrer"
									href={
										item?.type === "mail" && item?.url
											? `mailto:${item?.url}`
											: item?.url || "#"
									}>
									{item?.icon}
								</a>
							</li>
						))}
					</ul>
					{/* <div className=" flex items-center gap-5">
						<a href="https://www.facebook.com/profile.php?id=61555713350051">
							<img
								className=" w-[14px] h-[25px]"
								src={require("../../assets/v12facebook.png")}
								alt=""
							/>
						</a>

						<a href="https://www.instagram.com/V12pay1">
							<img
								className=" w-[25px] h-[25px]"
								src={require("../../assets/v12instagram.png")}
								alt=""
							/>
						</a>
					</div> */}
				</div>
				{/* <ul className=" grid lg:gap-0 md:gap-5 gap-3">
          <li className=" font-bold font-kumbh">Company</li>
          <Link to="about" className=" no-underline text-white ">
            About
          </Link>
          <Link to="product" className=" no-underline text-white ">
            Products
          </Link>
          <Link to="contact" className=" no-underline text-white ">
            Contact Us
          </Link>
        </ul> */}
				{/* <ul className=" grid lg:gap-0 md:gap-5 gap-3">
          <li className=" font-bold font-kumbh">Policy</li>
          <Link to="register" className=" no-underline text-white ">
            Why Us?
          </Link>
          <Link to="register" className=" no-underline text-white ">
            Partner with us
          </Link>
          <Link to="register" className=" no-underline text-white ">
            Terms & Conditions
          </Link>
        </ul> */}
				<ul
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" grid lg:gap-0 md:gap-5 gap-3">
					<li className=" font-bold font-kumbh">Contact</li>
					<Link className=" no-underline text-white ">
						{process.env.REACT_APP_AGENT_TELEPHONE}
					</Link>
					<Link className=" no-underline text-white ">
						{process.env.REACT_APP_AGENT_EMAIL}
					</Link>
					<Link className=" no-underline text-white w-[206px]">
						{process.env.REACT_APP_AGENT_ADDRESS}
					</Link>
				</ul>
			</div>
		</div>
	);
};

export default HomeFourteen;
