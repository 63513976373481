import React from "react";
import HomeThirteenNav from "../../Component/home-thirteen/HomeThirteenNav";
import HThirteenbg from "../../assets/HThirteenbg.png";
import HThirteenimg from "../../assets/HThirteenGroup42945.png";
import { useNavigate } from "react-router-dom";
import AppLogo from "../../Component/app-logo";
import { socials } from "../../Component/Footer";

const HomeThirteen = () => {
	const card = [
		{
			id: 1,
			image: HThirteenimg,
			heading: "Data Services",
			text: `${process.env.REACT_APP_NAME} is a reliable and affordable platform for purchasing affordable data bundles for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			button: "Read more",
		},
		{
			id: 2,
			image: HThirteenimg,
			heading: "Airtime Pin Service",
			text: `${process.env.REACT_APP_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime. `,
			button: "Read more",
		},
		{
			image: HThirteenimg,
			heading: "Airtime Services",
			text: `${process.env.REACT_APP_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
			id: 3,
			button: "Read more",
		},
		{
			image: HThirteenimg,
			heading: "Bill Payment",
			text: "Do you want to stay on top of your finances, then make you can make use of our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools. ",
			id: 4,
			button: "Read more",
		},
		{
			image: HThirteenimg,
			heading: "Cable Subscription",
			text: `At ${process.env.REACT_APP_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
			id: 5,
			button: "Read more",
		},
		{
			image: HThirteenimg,
			heading: "Education",
			text: `${process.env.REACT_APP_NAME} is your go-to source for various examination scratch cards, including those for Waec, Neco, and Nabteb. We take pride in providing top-quality service and aim to make our website your go-to destination after your first experience with us. `,
			id: 5,
			button: "Read more",
		},
	];

	let navigate = useNavigate();

	return (
		<div>
			<HomeThirteenNav />

			<div
				className=" mt-10  bg-cover h-screen bg-center bg-no-repeat"
				style={{
					backgroundImage: `url(${HThirteenbg})`,
					backgroundSize: "150% 105%",
				}}>
				<div className="px-8 md:px-16 lg:px-28">
					<img
						className=" lg:block md:block hidden ml-16 mt-16 w-[179.68px] h-[179.68px]"
						src={require("../../assets/HThirteenEllipse4.png")}
						alt=""
					/>
				</div>

				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid justify-center lg:mt-[-25px] md:mt-20 mt-[200px] items-center px-8 md:px-16 lg:px-auto">
					<p className=" text-center font-DM_Sans font-bold lg:text-[60px] md:text-[60px] text-[50px] bg-gradient-to-r from-[#4FBEF7] to-[#2A6CE0] text-transparent bg-clip-text">
						{process.env.REACT_APP_NAME} Fast. Accurate. Reliable
					</p>
					<p className=" text-center font-DM_Sans lg:text-[20px] lg:w-[822px] ">
						We prioritize customer satisfaction by offering an extensive range
						of choices, ensuring their happiness through diverse options and
						personalized solutions for a more fulfilling experience.
					</p>
					<div className=" flex justify-center items-center gap-2 mt-20 ">
						<button
							onClick={() => navigate("/register")}
							className="bg-[#3179E4] hover:bg-[#7EC4E6] lg:px-5 md:px-5 px-4 py-2 text-white rounded-full text-[18px] hover:scale-105 ease-in duration-300 ">
							Get Started
						</button>
						<img
							className="lg:w-[32px] md:w-[32px] w-[25px] animate-bounce"
							src={require("../../assets/HThirteenArrow.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			{/* Section two */}
			<div
				name="product"
				className="  lg:mt-[-40px] md:mt-[-400px] mt-[-200px] px-8 md:pl-16 lg:pl-28 lg:pr-0 py-20 bg-gradient-to-tr from-[#4CB8F5] via-[#1D4ED8] to-[#1D4ED8] lg:flex md:flex grid justify-center items-center gap-10">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" lg:w-1/2 md:w-1/2 w-full text-white">
					<p className=" text-[16px] font-DM_Sans ">WHY CHOOSE US</p>
					<p className=" font-DM_Sans text-[24px]">
						We have the BEST Features For Your Subscriptions. You can trust us
						with your subscriptions.
					</p>
					<p>
						In a few clicks, buy data to keep surfing the internet. You can buy
						whatever size of data plan for whichever network you desire.
					</p>
					<div>
						<div className=" flex items-center gap-2">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/HThirteenFrame74.png")}
								alt=""
							/>
							<p className=" font-DM_Sans pt-3">Secure Transactions</p>
						</div>
						<div className=" flex items-center gap-2">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/HThirteenFrame74.png")}
								alt=""
							/>
							<p className=" font-DM_Sans pt-3">Affordable Plans</p>
						</div>
						<div className=" flex items-center gap-2">
							<img
								className=" w-[32px] h-[32px]"
								src={require("../../assets/HThirteenFrame74.png")}
								alt=""
							/>
							<p className=" font-DM_Sans pt-3">24/7 Online Support</p>
						</div>
					</div>

					<button
						onClick={() => navigate("/login")}
						className=" rounded-md mt-10 px-3 py-2 font-DM_Sans bg-white text-black hover:bg-black hover:text-white hover:scale-105 duration-300 ease-in">
						Learn More
					</button>
				</div>
				<div
					data-aos="fade-down"
					data-aos-duration="3000"
					className=" lg:w-1/2 md:w-1/2 w-full">
					<img src={require("../../assets/HThirteenChart.png")} alt="" />
				</div>
			</div>

			{/* section three */}
			<div
				data-aos="fade-left"
				data-aos-duration="3000"
				className=" px-8 md:px-16 lg:px-28 py-20 lg:flex md:flex grid gap-10 items-center justify-center">
				<div className=" lg:w-1/2 md:w-1/2 w-full">
					<p className=" text-[40px] font-bold font-DM_Sans bg-gradient-to-r from-[#4FBEF7] to-[#2A6CE0] text-transparent bg-clip-text">
						Benefits you gain with US
					</p>
					<p className=" lg:w-[441px]">
						Empower Your Financial Journey with the Ultimate Solution for
						Convenience and experience the ease and security of managing your
						finances with our innovative and comprehensive solution.
					</p>
					<div>
						<div className=" flex items-center gap-2">
							<img
								className="w-[32px] h-[32px]"
								src={require("../../assets/HThirteenGroup6.png")}
								alt=""
							/>
							<p className=" font-DM_Sans mt-3">
								Best interest rates on the market.
							</p>
						</div>
						<div className=" flex items-center gap-2">
							<img
								className="w-[32px] h-[32px]"
								src={require("../../assets/HThirteenGroup6.png")}
								alt=""
							/>
							<p className=" font-DM_Sans mt-3">Prevent unstable prices</p>
						</div>
						<div className=" flex items-center gap-2">
							<img
								className="w-[32px] h-[32px]"
								src={require("../../assets/HThirteenGroup6.png")}
								alt=""
							/>
							<p className=" font-DM_Sans mt-3">Best price on the market</p>
						</div>
						<div className=" flex items-center gap-2">
							<img
								className="w-[32px] h-[32px]"
								src={require("../../assets/HThirteenGroup6.png")}
								alt=""
							/>
							<p className=" font-DM_Sans mt-3">Security of your data</p>
						</div>
					</div>
				</div>

				<div className=" lg:w-1/2 md:w-1/2 w-full">
					<img src={require("../../assets/HThirteenGroup43024.png")} alt="" />
				</div>
			</div>

			{/* section four */}
			<div
				name="about"
				className="px-8 md:px-16 lg:px-28 py-20 bg-gradient-to-tr from-[#4CB8F5] via-[#1D4ED8] to-[#1D4ED8] ">
				<div
					data-aos="fade-up"
					data-aos-duration="3000"
					className=" grid justify-center items-center text-white ">
					<p className=" lg:text-[48px] md:text-[52px] text-[22px] font-DM_Sans font-bold text-center">
						Our Services Made Specially for YOU!
					</p>
					<p className=" text-center font-DM_Sans">
						We have the BEST Features For Your Subscriptions. You can trust us
						with your subscriptions.
					</p>
				</div>
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-5 pt-10">
					{card.map(items => (
						<div
							key={items.id}
							className=" justify-center items-center text-white font-DM_Sans ">
							<div className=" flex gap-4">
								<div className="w-[70px] h-[70px]">
									<img src={items.image} alt="" />
								</div>
								<div>
									<p className=" font-DM_Sans text-[20px]">{items.heading}</p>
									<p className=" font-DM_Sans">{items.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* section five */}
			<div
				data-aos="fade-left"
				data-aos-duration="3000"
				className="px-8 md:px-16 lg:px-28 py-20 ">
				<div className=" grid justify-center items-center">
					<p className=" text-center lg:text-[36px] md:text-[36px] text-[25px] font-bold font-DM_Sans lg:w-[709px]">
						Embracing A Brighter Financial Life With{" "}
						{process.env.REACT_APP_NAME}, Start Today!
					</p>
				</div>

				<div className=" flex justify-center items-center mt-10">
					<button
						onClick={() => navigate("/login")}
						className="bg-[#3179E4] hover:bg-[#7EC4E6] lg:px-5 md:px-5 px-4 py-2 text-white rounded-md text-[18px] hover:scale-105 ease-in duration-300 ">
						Get Started
					</button>
				</div>
			</div>

			{/* section six */}

			<div name="contact" className="px-8 md:px-16 lg:px-28 pb-10">
				<div className=" bg-[#1D4ED8] lg:flex md:flex grid justify-center items-center py-3 px-10 rounded-md gap-5">
					<div
						data-aos="fade-right"
						data-aos-duration="3000"
						className="lg:w-1/2 md:w-1/2 w-full text-white">
						<p className=" font-bold lg:text-start md:text-start sm:text-center">
							Newsletter
						</p>
						<p className="lg:text-start md:text-start sm:text-center">
							Be the first to know about discounts, offers and news
						</p>
					</div>
					<div
						data-aos="fade-left"
						data-aos-duration="3000"
						className="lg:w-1/2 md:w-1/2 w-full flex items-center justify-between">
						<img
							className=" h-[16px] w-[16px] relative z-10 left-2"
							src={require("../../assets/HThirteenIcon.png")}
							alt=""
						/>
						<input
							className=" lg:w-[457px] md:w-[310px] w-[294px] h-[48px] bg-[#112F82] rounded-md absolute z-0 pr-[130px] pl-8 text-white"
							type="text"
							placeholder="Enter your email"
						/>
						<button className=" relative z-10 lg:right-8 bg-white hover:bg-[#7EC4E6] lg:px-5 md:px-5 px-4 py-2 text-black rounded-md text-[18px] hover:scale-105 ease-in duration-300 ">
							Submit
						</button>
					</div>
				</div>
			</div>

			{/* footer */}
			<div className=" bg-[#F0FBFF]  text-black">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className="md:px-16 lg:px-28 px-8 py-10">
					<div className=" lg:flex md:flex grid justify-between lg:gap-0 md:gap-0 gap-10">
						<div className=" grid gap-3 ">
							<div className=" text-lg font-semibold flex items-center">
								<AppLogo height={"50px"} />
								<p className=" font-bold text-[24px] font-DM_Sans mt-4">
									{process.env.REACT_APP_NAME}
								</p>
							</div>
							<div className=" lg:w-[250px]">
								<p className=" font-Inter lg:w-full md:w-[250px] w-full">
									We prioritize customer satisfaction by offering an extensive
									range of choices, ensuring their happiness through diverse
									options and personalized solutions for a more fulfilling
									experience.
								</p>
							</div>
							{/* <div className=" flex gap-3 items-center">
								<img
									className="w-[26px] h-[26px]"
									src={require("../../assets/HThirteenVector.png")}
									alt=""
								/>
								<img
									className="w-[26px] h-[26px]"
									src={require("../../assets/HThirteenGroup.png")}
									alt=""
								/>
								<img
									className="w-[26px] h-[26px]"
									src={require("../../assets/HThirteenGroup8.png")}
									alt=""
								/>
							</div> */}
							<ul className="list-group border-0 list-group-horizontal">
								{socials.map((item, index) => (
									<li
										key={index}
										className="list-group-item border-0 bg-transparent text-[#3179E4] d-flex align-items-center">
										<a
											className="text-[#3179E4] text-decoration-none fontReduce"
											target={"_blank"}
											rel="noreferrer"
											href={
												item?.type === "mail" && item?.url
													? `mailto:${item?.url}`
													: item?.url || "#"
											}>
											{item?.icon}
										</a>
									</li>
								))}
							</ul>
						</div>
						<ul className="grid">
							<p className=" text-[16px] font-bold font-DM_Sans">Company</p>
							<li className=" text[16px] font-Inter">About</li>
							<li className=" text[16px] font-Inter">Products</li>
							<li className=" text[16px] font-Inter">Home</li>
						</ul>

						<ul className="grid">
							<p className=" text-[16px] font-bold font-DM_Sans ">Privacy</p>
							<li className=" text[16px] font-Inter">Why Us?</li>
							<li className=" text[16px] font-Inter">Partner with us</li>
							<li className=" text[16px] font-Inter">Terms & Conditions</li>
						</ul>

						<ul className="grid lg:mt-0 md:mt-0 mt-0">
							<p className=" text-[16px] font-bold font-DM_Sans">Contact</p>
							<li className=" text[16px] font-Inter">
								{process.env.REACT_APP_AGENT_TELEPHONE}
							</li>
							<li className=" text[16px] font-Inter">
								{process.env.REACT_APP_AGENT_EMAIL}
							</li>
							<li className=" text[16px] font-Inter">
								{process.env.REACT_APP_AGENT_ADDRESS}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeThirteen;
