export const data = [
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#F3180B]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4385 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4385 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9137 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "data service",
		description: `${process.env.REACT_APP_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo`,
	},
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#FFAD00]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4386 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4386 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9138 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "airtime service",
		description: `${process.env.REACT_APP_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime.`,
	},
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#1ACC77]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4386 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4386 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9138 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "cable subscription",
		description:
			"We offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.",
	},
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#1ACC77]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4386 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4386 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9138 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "electricity",
		description:
			"Do you want to stay on top of your finances, then make you can make use of our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
	},
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#F3180B]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4386 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4386 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9138 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "education",
		description:
			"Do you want to stay on top of your finances, then make you can make use of our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
	},
	{
		icon: (
			<span className="h-20 w-20 rounded-full flex items-center justify-center bg-[#FFAD00]">
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M20.3512 15.2737C20.4386 15.3608 20.5078 15.4643 20.5551 15.5782C20.6023 15.6921 20.6267 15.8142 20.6267 15.9375C20.6267 16.0608 20.6023 16.1829 20.5551 16.2968C20.5078 16.4107 20.4386 16.5141 20.3512 16.6012L14.7262 22.2262C14.6392 22.3135 14.5357 22.3828 14.4218 22.4301C14.3079 22.4773 14.1858 22.5016 14.0625 22.5016C13.9392 22.5016 13.8171 22.4773 13.7032 22.4301C13.5893 22.3828 13.4858 22.3135 13.3987 22.2262L10.5862 19.4137C10.4991 19.3266 10.4299 19.2231 10.3828 19.1092C10.3356 18.9953 10.3113 18.8732 10.3113 18.75C10.3113 18.6267 10.3356 18.5046 10.3828 18.3907C10.4299 18.2769 10.4991 18.1734 10.5862 18.0862C10.7623 17.9102 11.001 17.8113 11.25 17.8113C11.3733 17.8113 11.4953 17.8356 11.6092 17.8827C11.7231 17.9299 11.8266 17.9991 11.9138 18.0862L14.0625 20.2368L19.0237 15.2737C19.1108 15.1864 19.2143 15.1171 19.3282 15.0699C19.4421 15.0226 19.5642 14.9983 19.6875 14.9983C19.8108 14.9983 19.9329 15.0226 20.0468 15.0699C20.1607 15.1171 20.2642 15.1864 20.3512 15.2737Z"
						fill="white"
					/>
					<path
						d="M15 1.875C16.2432 1.875 17.4355 2.36886 18.3146 3.24794C19.1936 4.12701 19.6875 5.3193 19.6875 6.5625V7.5H10.3125V6.5625C10.3125 5.3193 10.8064 4.12701 11.6854 3.24794C12.5645 2.36886 13.7568 1.875 15 1.875ZM21.5625 7.5V6.5625C21.5625 4.82202 20.8711 3.15282 19.6404 1.92211C18.4097 0.691404 16.7405 0 15 0C13.2595 0 11.5903 0.691404 10.3596 1.92211C9.1289 3.15282 8.4375 4.82202 8.4375 6.5625V7.5H1.875V26.25C1.875 27.2446 2.27009 28.1984 2.97335 28.9016C3.67661 29.6049 4.63044 30 5.625 30H24.375C25.3696 30 26.3234 29.6049 27.0266 28.9016C27.7299 28.1984 28.125 27.2446 28.125 26.25V7.5H21.5625ZM3.75 9.375H26.25V26.25C26.25 26.7473 26.0525 27.2242 25.7008 27.5758C25.3492 27.9275 24.8723 28.125 24.375 28.125H5.625C5.12772 28.125 4.65081 27.9275 4.29917 27.5758C3.94754 27.2242 3.75 26.7473 3.75 26.25V9.375Z"
						fill="white"
					/>
				</svg>
			</span>
		),
		title: "recharge card/subscription",
		description:
			"is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo",
	},
];
