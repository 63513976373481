import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
	once: false,
});

const title = document.querySelector("title");
const decsciption = document.querySelectorAll("meta")[3];

title.innerText = `${process.env.REACT_APP_NAME}: Buy Airtime and Data for all Network. Make payment for DSTV, GoTv`;
decsciption.setAttribute(
	"content",
	`${process.env.REACT_APP_NAME} is a website that provides you with easy access to data,Buy Cheap Internet Data Plan and Airtime Recharge`
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
