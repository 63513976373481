const Card = ({ img, title, text }) => {
  return (
		<div
			data-aos="zoom-in-up"
			data-aos-duration="2000"
			className="text-center bg-white rounded-lg p-8 shadow-md">
			<img
				src={require(`../../assets/img/${img}`)}
				className="mx-auto h-10"
				alt=""
			/>
			<p className="font-bold mt-8">{title}</p>
			<p className="font-light mt-4">{text}</p>
		</div>
	);
};

export default Card;
